<template>
	<div class="qualification">
		<Banner :list="banners"></Banner>
		<div class="qualification-main container">
			<!-- <passage></passage> -->
			<menus></menus>
			<div class="qualification-main-major mb-6">
				<div class="qualification-main-major-l-img text-center mb-5">
					<img src="@/assets/image/serve/title-test.jpg" />
				</div>
				<div class="main-title pt-0">
					<h1>专业承包资质服务</h1>
					<h3>业务范围覆盖全国 7*24小时无忧服务</h3>
				</div>

				<div class="qualification-main-major-l">
					<div class="swiper-container serve-swiper mt-5 mb-2 position-relative pb-3">
						<div class="swiper-wrapper" >
							<template v-for="(item, index) in datas">
								<div class="swiper-slide" :key="index">
									<div class="qualification-main-major-r flex flex-wrap">
										<template v-for="(ele,li) in item.list">
									
											<div class="list text-center" :key="li" @click="navWenzhang">
												<div class="list-img w-100">
													<div class="list-img-bg bg-cent m-auto" :style="{backgroundImage:'url(' + ele.img + ')' }"></div>
												</div>
												<span class="m-auto" style="width: 220px;">{{ele.title}}</span>
											</div> 
									
										</template>
									</div>
								</div>
							</template>
						</div>
						<div class="swiper-pagination position-absolute w-100 " style="bottom: 0px;"></div>
					</div>
					
					<div class="swiper-container qualification-container mt-5 mb-2" style="height: 50px;">
						<div class="swiper-wrapper" style="height: 50px;">
							<template v-for="(item, index) in historyList">
								<div class="swiper-slide" style="height: 50px;" :key="index">
									<div class="run-test">
										<span class="text-color">{{ item.split(' ')[0] }}</span>
										<span class="text-color-f">{{ item.split(' ')[1] }}</span>
										<span class="text-color">{{ item.split(' ')[2] }}</span>
										<span class="text-color-f">{{ item.split(' ')[3] }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="tbn flex justify-between align-center" >
						<div class="inp flex align-center">
							<img class="ml-1" src="@/assets/image/serve/zz.png" />
							<input v-model="offerData.requirements" placeholder="输入资质名称" />
						</div>
						<div class="inp flex align-center">
							<img src="@/assets/image/serve/phone.png" />
							<input v-model="offerData.phone" placeholder="输入联系方式" />
						</div>
						<div class="test flex-1" @click="getInfo">免费获取报价</div>
					</div>
				</div>

			</div>

		</div>

		<img class="my-png" width="100%" src="../../assets/image/serve/my-list.png" />
		
		<img class="my-png-m" width="100%" src="../../assets/image/serve/003.png" />
		
		<certificate ifcolor='show'></certificate>

		<videolist :fromData="fromData"></videolist>

		<div style="background: #FAFAFB;" class="py-1">
			<strengthnew></strengthnew>
		</div>
		<choose ifcolor='show'></choose>
		<news></news>
		<strength ifcolor='show'></strength>

	</div>
</template>

<script>
	import Banner from "@/components/common/banner";
	import passage from "@/components/common/passage";
	import message from "@/components/common/message";
	import choose from "@/components/common/choose";
	import strength from "@/components/common/strength";
	import connect from "@/components/common/connect";
	import questions from "@/components/common/questions";
	import Swiper from "swiper";
	export default {
		components: {
			Banner,
			passage,
			message,
			choose,
			strength,
			connect,
			questions,
		},
		data() {
			return {
				datas:[
					{
						list:[
							{img:require("@/assets/image/serve/01.png"),title:'铁路电务工程专业承包'},
							{img:require("@/assets/image/serve/02.png"),title:'铁路铺轨架梁工程专业承包'},
							{img:require("@/assets/image/serve/03.png"),title:'铁路电气化工程专业承包'},
							{img:require("@/assets/image/serve/04.png"),title:'机场场道工程专业承包'},
							{img:require("@/assets/image/serve/05.png"),title:'民航空管工程及机场弱电系统工程专业承包'},
							{img:require("@/assets/image/serve/06.png"),title:'机场目视助航工程专业承包'},
							{img:require("@/assets/image/serve/07.png"),title:'港口与海岸工程专业承包'},
							{img:require("@/assets/image/serve/08.png"),title:'航道工程专业承包'},
						],
					},
					{
						list:[
							{img:require("@/assets/image/serve/09.png"),title:'通航建筑物工程专业承包'},
							{img:require("@/assets/image/serve/10.png"),title:'港航设备安装及水上交管工程专业承包'},
							{img:require("@/assets/image/serve/11.png"),title:'水工金属结构制作与安装工程专业承包'},
							{img:require("@/assets/image/serve/12.png"),title:'水利水电机电安装工程专业承包'},
							{img:require("@/assets/image/serve/13.png"),title:'河湖整治工程专业承包'},
							{img:require("@/assets/image/serve/14.png"),title:'输变电工程专业承包'},
							{img:require("@/assets/image/serve/15.png"),title:'核工程专业承包'},
							{img:require("@/assets/image/serve/16.png"),title:'海洋石油工程专业承包'},
						],
					},
					{
						list:[
							{img:require("@/assets/image/serve/17.png"),title:'环保工程专业承包'},
							{img:require("@/assets/image/serve/18.png"),title:'特种工程专业承包'},
							{img:require("@/assets/image/serve/19.png"),title:'地基基础工程专业承包'},
							{img:require("@/assets/image/serve/20.png"),title:'起重设备安装工程专业承包'},
							{img:require("@/assets/image/serve/21.png"),title:'预拌混凝土专业承包'},
							{img:require("@/assets/image/serve/22.png"),title:'电子与智能化工程专业承包'},
							{img:require("@/assets/image/serve/23.png"),title:'消防设施工程专业承包'},
							{img:require("@/assets/image/serve/24.png"),title:'防水防腐保温工程专业承包'},
						],
					},
					{
						list:[
							{img:require("@/assets/image/serve/25.png"),title:'钢结构工程专业承包'},
							{img:require("@/assets/image/serve/26.png"),title:'模板脚手架专业承包'},
							{img:require("@/assets/image/serve/27.png"),title:'建筑装修装饰工程专业承包'},
							{img:require("@/assets/image/serve/28.png"),title:'建筑机电安装工程专业承包'},
							{img:require("@/assets/image/serve/29.png"),title:'建筑幕墙工程专业承包'},
							{img:require("@/assets/image/serve/30.png"),title:'古建筑工程专业承包'},
							{img:require("@/assets/image/serve/31.png"),title:'公路路面工程专业承包'},
							{img:require("@/assets/image/serve/32.png"),title:'公路路基工程专业承包'},
						],
					},
					{
						list:[
							{img:require("@/assets/image/serve/34.png"),title:'公路交通工程专业承包（公路机电工程）'},
							{img:require("@/assets/image/serve/35.png"),title:'公路交通工程'},
							{img:require("@/assets/image/serve/36.png"),title:'城市及道路照明工程'},
							{img:require("@/assets/image/serve/37.png"),title:'隧道工程'},
							{img:require("@/assets/image/serve/38.png"),title:'桥梁工程专业承包'},
						],
					}
				],
				offerData: {
					phone: '',
					engineer_num: '',
					type: 20
				},
				fromData: {
					subdoadmin: '',
					launchPage: 30,
					classify: 20,
					scope: 0
				},
				banners: {
					title: "36项专业承包资质办理",
					desc: "一价全包 无隐藏费用 全过程免费咨询",
					list: [require("@/assets/image/banner/2.jpg")],
				},
				childList: [],
				historyList: [
					"3个月前 139****0234用户 已成功办理 【建筑装修装饰工程资质】",
					"10个小时前 158****2216用户 已成功办理 【建筑工程总承包资质】",
					"21个小时前 146****81374用户 已成功办理 【市政工程总承包资质】",
					"1天前 176****9131用户 已成功办理 【建筑机电安装专业承包资质】",
					"3天前 188****6666用户 已成功办理 【消防工程专业承包资质】",
					"1个月前 136****1680用户 已成功办理 【机电总包资质】",
					"23小时前 133****1268用户 已成功办理 【环保工程资质】",
					"5天前 132****9852用户 已成功办理 【承装修试三级】",
					"10天前 180****7512用户 已成功办理 【环保设计资质】",
					"12天前 172****5124用户 已成功办理 【房建一级资质】",
					"10个小时前 138****8888用户 已成功办理 【建筑装修装饰工程资质】",
					"2天前 136****6266用户 已成功办理 【模板脚手架工程资质】",
					"6个小时前 136****4288用户 已成功办理 【钢结构工程资质】",
					"4天前 136****6836用户 已成功办理 【电子智能化工程资质】",
					"6天前 153****6661用户 已成功办理 【承装修试电力工程资质】",
					"4个小时前 151****0108用户 已成功办理 【建筑装修装饰工程资质】",
					"5个小时前 187****5222用户 已成功办理 【环保工程资质】",
				],
			};
		},
		methods: {
			navWenzhang(){
				this.$router.push('/article?id=34&flag=criterion&except=0&categoryId=147&professionId=0')
			},
			_init_swiper() {
				var swiper = new Swiper(".qualification-container", {
					autoplay: 800,
					loop: true,
					direction: 'vertical',
					// pagination : '.swiper-pagination',
				});
			},
			_serve_swiper() {
				var swiper = new Swiper(".serve-swiper", {
					// autoplay: 2000,
					loop: true,
					pagination : '.swiper-pagination'
				});
			},
			// 提交信息
			getInfo() {

				let source_url = window.location.href;
				if (!/^1[3456789]\d{9}$/.test(this.offerData.phone)) {
					this.$message.error("请输入正确手机号");
					return;
				}

				this.offerData.source_url = source_url;
				this.$api.applyOffer(this.offerData).then((res) => {
					this.$message.success("提交成功，稍后专业顾问将为你提供专业报价");
				}).catch(err => {
					this.$message.error("提交失败", err);
				});
			},
			getBannerList() {
				this.$api
					.getBannerList({
						// 10首页  20
						launchPage: 30,
						launchFacility: 10,
					})
					.then((res) => {
						this.banners.list = res.data.list;
					});
			},
			getCategoryChild() {
				this.$api
					.getCategoryChild({
						pid: 92,
					})
					.then((res) => {
						this.childList = res.data;
						this.$nextTick(() => {
							this.initServeSwiper();
							this._serve_swiper();
						});
					});
			},
			initServeSwiper() {
				var strenghSwiper = new Swiper(".serve-container", {
					pagination: ".swiper-pagination",
				});
			},
		},
		mounted() {
			this._init_swiper();
		},
		created() {
			this.getBannerList();
			this.getCategoryChild();
		},
	};
</script>

<style lang="scss">
	@font-face {
		font-family: "HYYaKuHeiW";
		src: url(../../assets/font/HYYaKuHeiW.ttf);
		font-weight: normal;
		font-style: normal;
	}

	@import "@/assets/css/qualification.scss";
	@import "@/assets/css/contract.scss";
	
	.my-png-m{
		display: none;
	}
	@media only screen and (max-width: 1024px) {
		
	}
	
	@media only screen and (max-width: 750px) {}
	
	
	@media only screen and (max-width: 481px) {
		.my-png{
			display: none;
		}
		.my-png-m{
			display: block;
		}
	}
</style>
